import { FC, ReactElement, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { useLazyAuthControllerRefreshQuery } from '@app/api/auth-api';
import { setAccessToken, setUserData } from '@app/store/reducers/authSlice';
import { useLazyExternalControllerFindOneQuery } from '@app/api/external-api';

export const AuthLoader: FC<{ children: ReactElement }> = ({ children }) => {
  const { phone } = useAppSelector((state) => state.authReducer.access);

  const dispatch = useAppDispatch();
  const [checkUserExists, { data: checkUserExistsRes }] = useLazyExternalControllerFindOneQuery();

  const [refresh, { data: refreshRes }] = useLazyAuthControllerRefreshQuery();

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (refreshRes?.accessToken) {
      dispatch(setAccessToken(refreshRes.accessToken));
    }
  }, [refreshRes, dispatch]);

  useEffect(() => {
    if (phone) {
      checkUserExists({ phone });
    }
  }, [phone, checkUserExists]);

  useEffect(() => {
    if (checkUserExistsRes) {
      dispatch(setUserData(checkUserExistsRes));
    }
  }, [checkUserExistsRes, dispatch]);

  return children;
};
