import { FC } from 'react';
import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow.svg';
import { useEnhancedTranslation } from '@app/hooks/enhanced-translation.hook';
import { useMemo } from 'react';

interface SubscribeEmailProps {
  email: string;
  emailLabel: string;
  isMobile: boolean;
  onChangeEmail: (e: React.ChangeEvent<HTMLInputElement>) => void;
  submitEmail: () => void;
}

const SubscribeEmail: FC<SubscribeEmailProps> = ({
  email,
  emailLabel,
  isMobile,
  onChangeEmail,
  submitEmail,
}): JSX.Element => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'footer' });

  const isShowButton = useMemo(() => {
    return isMobile || !!email;
  }, [isMobile, email]);

  return (
    <label className="footer__email-label" htmlFor="your-email">
      <input
        className="footer__email-input"
        onChange={onChangeEmail}
        onKeyDown={({ key }) => key === 'Enter' && submitEmail()}
        name="your-email"
        placeholder={t('yourEmail') || ''}
        type="email"
      />
      <span className="footer__email-label-text">{t(emailLabel)}</span>
      {isShowButton && (
        <button onClick={submitEmail} type="submit" aria-label="submit email" className="footer__email-submit">
          <ArrowIcon className="footer__email-submit-icon" />
        </button>
      )}
    </label>
  );
};
export default SubscribeEmail;
