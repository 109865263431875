import { connectEmptyApi as api } from '../connect-empty-api';
import {
  FooterControllerFirstApiResponse,
} from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    footerControllerFirst: build.query<FooterControllerFirstApiResponse, void>({
      query: () => ({
        url: '/api/v1/footer',
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as colorApi };

export const {
  useLazyFooterControllerFirstQuery,
} = injectedRtkApi;
