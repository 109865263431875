import { colorApi as api } from '../footerApi';

export const footerTaggedApi = api.enhanceEndpoints({
  addTagTypes: ['Footer'],
  endpoints: {
    footerControllerFirst: {
      providesTags: ['Footer'],
    },
  },
});
