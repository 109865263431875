import * as yup from 'yup';

type TValidResponse<T> = { valid: true; data: T };
type TInvalidResponse = { valid: false; errors: string };
export const validateSchema = async <T>(
  schema: yup.Schema<T> | yup.ObjectSchema<any>,
  data: T,
): Promise<TValidResponse<T> | TInvalidResponse> => {
  try {
    await schema.validate(data, { abortEarly: true }); // Останавливаемся на первой ошибке
    return { valid: true, data };
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      return { valid: false, errors: err.message };
    }
    throw err; // Перебрасываем неизвестные ошибки
  }
};
